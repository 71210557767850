import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import LazyLoad from "components/LazyLoad"; // Import the LazyLoad component

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;
const AuthorProfile = tw.div`pt-1 font-medium`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw`text-2xl`}
    }
    ${PostDescription} {
      ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
    }
    ${AuthorInfo} {
      ${tw`mt-8 flex items-center`}
    }
    ${AuthorName} {
      ${tw`mt-0 font-bold text-gray-700 text-sm`}
    }
  `}
`;

const DecoratorBlob1 = tw(SvgDotPatternIcon)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
const DecoratorBlob2 = tw(SvgDotPatternIcon)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`

export default ({
  subheading = "Discover the Future of Storage",
  heading = (
    <>
      We make them
      <span tw="text-primary-500"> smart.</span>
    </>
  ),
  description = "Explore how our innovative cloud-connected smart lockers can revolutionize your storage experience, ensuring convenience, security, and efficiency.",

  posts = [
    {
      postImageSrc:
        "LOCKERS_E.png",
      title: "Unlocking the Future: Cloud-Connected Smart Lockers",
      featured: true,
      description:
        "From remote user support to dynamic feature adjustments tailored to each location, our innovative cloud-connected software empowers you to provide the ultimate locker experience. Say goodbye to long wait times with automated occupancy management, ensure locker cleanliness with user feedback mechanisms, and empower on-site administrators with comprehensive control options. And with our advanced in-house software, every operation is perfectly synchronized for a seamless user experience. Welcome to the future of smart storage.",
    },
    {
      postImageSrc:
        "COVER_Q.png",
      title: "App for B2C business.",
      authorName: "Flexible monetization options to suit your business needs.",
    },
    {
      postImageSrc:
        "LOCKERS_D.png",
      title: "Kiosk with various authentication methods",
      authorName: "Our Control units allow fingerprint, RFID, QR, Pin-CODE and APP",
    },
    {
      postImageSrc:
        "CODE_A.png",
      title: "Dedicated API's",
      authorName: "Integrate with your own system through our dedicated API's",
    },
    {
      postImageSrc:
        "LOCKERS_STATS_B.PNG",
      title: "Gain insights with usage statistics",
      authorName: "Understand user behavior and trends for better decision-making.",
    }
  ]
}) => {
  return (
    <Container id='CloudLockers'>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post>
              {post.featured ? (
                <PostImage imageSrc={post.postImageSrc} />
              ) : (
                <LazyLoad>
                  <PostImage imageSrc={post.postImageSrc} />
                </LazyLoad>
              )}
                <PostText>
                  <PostTitle>{post.title}</PostTitle>
                  {post.featured && <PostDescription>{post.description}</PostDescription>}
                  <AuthorInfo>

                    <AuthorTextInfo>
                      <AuthorName>{post.authorName}</AuthorName>
                      {post.featured && <AuthorProfile>{post.authorProfile}</AuthorProfile>}
                    </AuthorTextInfo>
                  </AuthorInfo>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
