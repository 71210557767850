import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LazyLoad from "components/LazyLoad"; // Import LazyLoad

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-auto min-h-144`}
  background-image: url("LOCKERS_WORLD.png");
  background-alt: "Map showcasing MyLock smart lockers locations around the world";
`;

const OpacityOverlay = tw.div`z-20 absolute inset-0 bg-black opacity-25 `;

const HeroContainer = styled.div`
  ${tw`z-20 relative px-20 sm:px-12 mx-auto h-full flex justify-center items-center`}
`;

const Content = styled.div`
  ${tw`text-center text-gray-100`}
  max-width: 800px;
  padding: 0 20px; /* Adjust padding as needed */
`;

const Heading = styled.h1`
  ${tw`text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-black leading-snug mb-4`}
  span {
    ${tw`block`}
  }
`;

const SubHeading = styled.h2`
  ${tw`text-xl sm:text-2xl`}
`;

export default () => {
  return (
    
      <Container id="Worldmap" style={{ backgroundImage: `url('LOCKERS_WORLD.PNG')` }}>
        <LazyLoad>
        <OpacityOverlay />
        <HeroContainer>
          <Content>
            <Heading>
              <br />
              <br />
              <span tw="text-secondary-500">MyLock Smart Lockers</span>
              <span>around the world</span>
            </Heading>
            <SubHeading>We are across the world for 5 years now.</SubHeading>
            <SubHeading>Our scalable technology empowers us to rapidly expand and offer our connected product seamlessly anywhere in the world.</SubHeading>
          </Content>
        </HeroContainer>
        </LazyLoad>
      </Container>
    
  );
};
